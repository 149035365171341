<!-- eslint-disable import/no-cycle -->
<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col cols="12" sm="12">
        <user-view-user-timeline-card v-if="$can('tracking_read')" />
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { BRow, BCardText, BCol, BCard } from 'bootstrap-vue'
import UserViewUserTimelineCard from '@/components/userManager/detail/UserViewUserTimelineCard.vue'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    UserViewUserTimelineCard
  },
  data() {
    return {
      data: {
      },
    }
  },

}
</script>
